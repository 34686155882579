import BgServer from "../BgServer";
import { NuxtAxiosInstance } from "@nuxtjs/axios";

import TransportAxios from "../transport/TransportAxios";

import { Context } from '@nuxt/types'
import UserConnector from "../connectors/UserConnector";

import { IUserInfo } from "../connectors/SocketConnector";
import UserConnectorStarz from "~/modules/starz/UserConnectorStarz";
import logger from "~/modules/utility/logger";

interface StarzPlayer {
    token:string
}

export default class BgServerStarz extends BgServer {
    context:Context
    purchaseEnable: boolean
    player?: StarzPlayer
    payments ?: any
    connectorUserStarz?: UserConnectorStarz

    constructor(axios: NuxtAxiosInstance, context: Context) {
        super(axios);
        this.purchaseEnable = false;
        this.context = context
    }

    async init() {
        this.setupLocale()
        await this.doLogin();
        await super.init();
        if(this.connectorUserStarz) {
            await this.connectorUserStarz.doPollingAccount()
        }
    }

    async doLogin() {

        if(!this.connectorUserStarz)
            return;

        return this.doLoginPlayer();
    }

    private async doLoginPlayer() {
        console.log( 'Starz: performing doLoginPlayer ...' );
        if(!this.connectorUserStarz)
            return;

        let url = new URL(window.location.href);
        let token = url.searchParams.get("token");
        if (!token) {
            return;
        }
        return this.connectorUserStarz.doLoginStarz(token)
    }


    createUserConnector(transport:TransportAxios) {
        this.connectorUserStarz =  new UserConnectorStarz(this.socket, transport, this);
        this.connectorUserStarz.verbose = true;

        this.connectorUserStarz.on(UserConnector.EVENT_USER_INFO, (data: IUserInfo)=>{
            // TODO login even we already logined
            this.doAuthroizationAndRetry( async ()=> this.doLogin() )
        })
        return this.connectorUserStarz
    }

    setupLocale() {

        let url = new URL(window.location.href);
        let localeShort = url.searchParams.get("lang") || 'en';
        localeShort = localeShort.substring( 0, 2 )

        console.log('set locale', localeShort)

        const i18n = (<any>this.context.app.i18n)
        if(!i18n) {
            logger.warn("Not found i18n module");
            return;
        }
        try {
            i18n.setLocale(localeShort);
            i18n.setLocaleCookie(localeShort);
        }
        catch(e) {
            console.warn('wrong language code')
            i18n.setLocale('en');
            i18n.setLocaleCookie('en');
        }
    }
}



import { Plugin } from '@nuxt/types'

import logger from '~/modules/utility/logger'

const authWatcher : Plugin = async ( context ) => {
    console.log( 'context:', context )

    //pages player allowed to visit when NOT yet auth-ed:
    const AUTH_PAGES = [
        'login',
        'signup',
        'forgotPassword',
    ]

    function Update() {
        const loggedIn = context.store.state.loggedIn
        const page = context.route.name
        if ( ! context.app.router ) {
            logger.error( "No router to control player's login page" )
            return
        }
        if ( loggedIn ) {
            if ( AUTH_PAGES.includes( page as string ) ) {
                context.app.router.push( '/' )
            }
        }
        else {
            if ( AUTH_PAGES.includes( page as string ) == false ) {
                context.app.router.push( 'login' )
            }
        }
    }

    /* why we have interval here:
        - store.watch() doesn't report first state of loggedIn
        - initial player's page is unknown
        - there is no way in Vue to monitor (watch) current page
        - can't (don't) use context.route because it SOMETIMES doesn't change it's value
    */
    
    // Disable watcher, instead use midleware middleware/authCheck.ts
    // But in future we should restore watcher logic 
    // if we want redirect to login page immediately        
    // setInterval( Update, 500 )
}
 
export default authWatcher
import Vue from 'vue';



function SyncArray(state:any, fieldName:string, data:any[]) {
    // for each item     
    //  compare
    data.forEach( (game, index) => {
        Vue.set( (<any>state)[fieldName], index , game);
    });
}

/**
 * Берет все члены dataObject и пытается их скопирывать в state
 * Пытается обновить только то что изменилось
 * Добавляет реактивность если её нет
 * 
 * @param state 
 * @param dataObject 
 */
export default function DataSync(state:any, dataObject:any) {
    for (const key in dataObject) {
        if ( !Object.prototype.hasOwnProperty.call(dataObject, key) ||
            !Object.prototype.hasOwnProperty.call(state, key) )
            continue;
            
        const element:any = (<any>dataObject)[key];
        Vue.set( state, key, element);
        // if(Array.isArray(element)) {
        //     SyncArray(state, key, element);
        // }
        // else {
        //     Vue.set( state, key, element);
        // }               
    }
}
import Vue from "vue";
import VueGtag from "vue-gtag";
​
Vue.use(
    VueGtag,
    {
        config: {
            id: "G-9XHGMF5YJG",

        },
        enable: process.env.NODE_ENV === 'production',
    },
)
​
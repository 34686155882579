
import moment from 'moment'

//import * as spine from '@esotericsoftware/spine-canvas'

export function starts_in( t : number, d : string ) {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24

    const starts = new Date( t * 1000 )
    const current = new Date
    let dif = starts.getTime() - current.getTime()

    if (dif <= 0 ) {
        return '00:00:00'
    }

    const days = Math.floor( dif / DAY );
    dif -= days * DAY

    const hours = Math.floor( dif / HOUR );
    dif -= hours * HOUR

    const minutes = Math.floor( dif / MINUTE )
    dif -= minutes * MINUTE

    const seconds = Math.floor( dif / SECOND );

    const s = (v:number) => v.toString().padStart( 2, '0' )

    let r = ''
    if ( days > 0 ) r += days + d + ' '
    let sec = ''
    if (days === 0 && hours < 1) sec = ':' + s(seconds)
    return r + s(hours) + ':' + s(minutes) + sec
}

export function print_timestamp( t : number ) {
    //@ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    return moment( t * 1000 ).format('L') + ' ' + moment( t * 1000 ).format('LTS')
}

export function print_timestamp_day( t : number ) {
    //@ts-ignore
    let locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    return moment( t * 1000 ).format('LTS')
}

export function is_valid_email( email : string ) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test( email )
}

/** Get specified deep property from object with fallback to specified value if it cannot be retrieved.
@param object object to obtain property from
@param prop deeply sequenced array of properties to obtain
@param fallback value to return in case specified deep property cannot be retrieved
@param desc literal prefix for console error message when property cannot be retrieved. No message will be printed if not specified.*/
export function get_prop(
    object : any,
    prop : Array<any>,
    fallback : any = undefined,
    desc : any = undefined
) {
    for ( let i = 0; i < prop.length; ++ i )
    {
        const prop_name = prop[ i ]
        object = ( object === undefined || object === null ) ? undefined : object[ prop_name ]
        
        if ( object === undefined || object === null )
        {
            if ( desc ){
                console.error( desc, 'no', prop_name, 'property' )
            }
            return fallback
        }
    }
    return object
}

/** To obtain UTF-8 bytes for string 'Русский' use:
console.log( 'Русский:', new TextEncoder().encode( 'Русский' ) )
*/
export function from_utf8_bytes( bytes : number[] ) {
    return new TextDecoder('utf-8').decode( new Uint8Array( bytes ) )
}

/** Finds english translation for specified tournament name or returns input.*/
export function translate_tournament( caption : string, locale : string ) {
    if ( ! locale.includes( 'en' ) ) {
        return caption
    }
    const NAMES = [
        { ru: [
            208, 145, 208, 190, 208, 187,
            209, 140, 209, 136, 208, 190,
            208, 185,  32, 208, 191, 209,
            128, 208, 184, 208, 183,  33
          ], en:'Big prize!', },
        { ru: [
            208, 146, 208, 191, 208, 181,
            209, 128, 208, 181, 208, 180,
             32, 208, 183, 208, 176,  32,
             55,  53,  48,  48,  33
          ], en: 'Onward to 7500!', },
        { ru: [
            56,  48,  48,  48,  32, 208, 178,
            32, 208, 190, 208, 180, 208, 189,
           208, 184,  32, 209, 128, 209, 131,
           208, 186, 208, 184
         ], en: '8000 to one winner!' },
        { ru: [
            208, 146, 209, 139, 209, 133, 208,
            190, 208, 180, 208, 189, 208, 190,
            208, 185,  32, 209, 129, 209, 142,
            209, 128, 208, 191, 209, 128, 208,
            184, 208, 183
          ], en: 'Weekend surprise!' },
    ]
    for ( const t of NAMES ) {
        if ( caption.includes( from_utf8_bytes( t.ru ) ) ) {
            return t.en
        }
    }
    return caption
}
export function translate_currency( s : string, locale : string ) {
    if ( locale.includes( 'ru' ) && s.includes( 'YAN' ) ) {
        s = s.replace(
            /YAN/gi,
            //"Ян":
            from_utf8_bytes( [208, 175, 208, 189] )
        )
    }
    return s
}

export function spine_animation(
    canvas : any,
    json_name : string,
    atlas_name : string,
    animation_name : string | undefined = undefined,
) : ( a : string ) => any {
    //trying to build:
    return () => {}
    /*let lastFrameTime = Date.now() / 1000;
	const context = canvas.getContext("2d");
	const assetManager = new spine.AssetManager
	let skeleton : any, animationState : any, bounds : any;
	const skeletonRenderer = new spine.SkeletonRenderer(context);
    // skeletonRenderer.triangleRendering = true;
    let target_animation_name : string | undefined = animation_name

    // Load the assets.
    function load() {
        assetManager.loadText( json_name );
        assetManager.loadTextureAtlas( atlas_name );
        assetManager.loadAll()
            .then( on_load )
            .catch( ( e : any ) => {
                console.error( 'Failed to load spine animations.', e, 'Trying again ...' )
                load()
            } )
    }
    load()
    function on_load() {
        // Create the texture atlas and skeleton data.
        let atlas = assetManager.require( atlas_name );
        let atlasLoader = new spine.AtlasAttachmentLoader(atlas);
        let skeletonJson = new spine.SkeletonJson(atlasLoader);
        let skeletonData = skeletonJson.readSkeletonData(assetManager.require( json_name ));

        // Instantiate a new skeleton based on the atlas and skeleton data.
        skeleton = new spine.Skeleton(skeletonData);
        skeleton.setToSetupPose();
        skeleton.updateWorldTransform();
        bounds = skeleton.getBoundsRect();

        // Setup an animation state with a default mix of 0.2 seconds.
        var animationStateData = new spine.AnimationStateData(skeleton.data);
        animationStateData.defaultMix = 0.2;
        animationState = new spine.AnimationState(animationStateData);

        if ( target_animation_name ) {
            animationState.setAnimation(0, target_animation_name, true);
        }

        // Start rendering.
        requestAnimationFrame(render);
    }

	function render() {
		const now = Date.now() / 1000;
		const delta = now - lastFrameTime;
		lastFrameTime = now;

        const width = 1600
        const height = 1200
        canvas.width = width
        canvas.height = height
        skeleton.x = 0
        skeleton.scaleY = - 1

		// Update and apply the animation state, update the skeleton's
		// world transforms and render the skeleton.
		animationState.update(delta);
		animationState.apply(skeleton);
		skeleton.updateWorldTransform();
		skeletonRenderer.draw(skeleton);

		requestAnimationFrame(render);
	}

    return ( animation_name : string ) => {
        console.log( 'setting:', animation_name )
        target_animation_name = animation_name
        if ( animationState ) {
            animationState.setAnimation(0, animation_name, true);
        }
    }*/
}

/** 
 * THIS module send log to trace server for remote debugging
 */

//import socket from '../plugins/socket.io';
import logger from "js-logger"
//let Logger = require("js-logger");
//import { v4 as uuidv4 } from 'uuid';


let appInstanceId = ""; //uuidv4();

let MESSAGE_COUNT_LIMIT = 1000;    

let messageBuffer = [] as string[];
let flushInProgress = false;

let onFlash = async function():Promise<boolean> {    
    return true;
}

export async function doFlushMessages() {
    if(flushInProgress)
        return;
    
    try {
        flushInProgress = true;
        let len = messageBuffer.length;
        if(!await onFlash())
            return;

        if(messageBuffer.length == len)
            messageBuffer = [];
        else
            messageBuffer.splice(0, len);                                    
    }
    finally {
        flushInProgress = false;
    }        
}


export function SetOnFlush( cb: ()=>Promise<true> ) {
    onFlash = cb;
}
export function SetAppInstanceId(id:string) {
    appInstanceId = id;
}

function replaceErrors(key_unused:any, value:any) {
    if (value instanceof Error) {
        var error:any = {};

        Object.getOwnPropertyNames(value).forEach(function (key:any) {            
            error[key] = (<any>value)[key];
        });

        return error;
    }    

    return value;
}


function packMessages(messages:any[]):string 
{
    let message_pack = new Date().toISOString() + " : ";
    for( let key in messages) {        
        let param = messages[key];
        if(typeof param != "string")
            param = JSON.stringify(replaceErrors(null, param), replaceErrors);
        message_pack += param + " "  
    }    
    return "|" + appInstanceId + " | " + message_pack;    
}




logger.useDefaults();

let consoleHandler = logger.createDefaultHandler();
logger.setHandler(function (messages:any[], context:any) {	
    consoleHandler(messages, context);  

    if (messageBuffer.length >= MESSAGE_COUNT_LIMIT)
        messageBuffer.splice(0, 1);

    //if(flushInProgress)
    messageBuffer.push(packMessages(messages)); 
    
    doFlushMessages();
});


// /// Error to Json
// // https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
// if (!('toJSON' in Error.prototype)) {
//     Object.defineProperty(Error.prototype, 'toJSON', {
//         value: function () {
//             var alt = {} as { [key:string]:any };
//             let self:any = this;

//             Object.getOwnPropertyNames(this).forEach(function (key) {
//                 alt[key] = self[key];
//             }, this);
//             return alt;
//         },
//         configurable: true,
//         writable: true
//     });
// }


if(window) {
    window.onerror = function(errorMsg, url, lineNumber) {              
        logger.error("Global error ", errorMsg, url, lineNumber);  
    }

    window.addEventListener('error', function(event) {
        logger.error("Global error event", event.message, "event = ", event);
    });    
}


export default logger
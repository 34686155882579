
import type {AxiosInstance,AxiosRequestConfig} from 'axios';
// import Axios from 'axios';

import TransportInterface from "./TransportInterface"
import { AxiosOptions } from '@nuxtjs/axios';

export default class TransportAxios implements TransportInterface {
    //baseURL:string    
    axios:AxiosInstance
    options:AxiosRequestConfig
    logCb:(isRequest:boolean, data:any, params:any, instance?:any)=>any

    constructor(axios: AxiosInstance, options:AxiosRequestConfig = {}) {        
        //this.baseURL = baseURL;  
        this.axios = axios             
        this.options = options         
        this.logCb = (isRequest:boolean, data:any,params:any)=>{};

        //TODO with credentails
    }

    async get(uri:string, options:any = {}) {                
        return this.axios.get(uri, {...this.options, ...options})                        
    }

    async post(uri:string, data:any, options:any) {
        let logParams = {
            uri:uri,
            error:null,
        }

        let instance = this.logCb(true, data, logParams);
        //console.log(`post to ${uri}, data = ${JSON.stringify(data, null, '\t')} `);        
        try {
            let response = await this.axios.post(uri, data , {...this.options, ...options});
            this.logCb(false, response.data, logParams, instance);
            return response;
        }
        catch(err) {
            logParams.error = err;
            this.logCb(false, "Error: "+err.message, logParams, instance);
            throw err;
        }
    }
}
import { Plugin } from '@nuxt/types'

// declare module '@nuxt/types' {
//   interface Context {
//     $myInjectedFunction(message: string): void
//   }
// }

import Vue from 'vue'
import logger from '~/modules/utility/logger'
import BgServer from '~/modules/BgServer'
import UserConnector from '~/modules/connectors/UserConnector'

import BgServerFb from '~/modules/facebook/BgServerFb'
import BgServerYandex from '~/modules/yandex/BgServerYandex'
import BgServerPhoenix from '~/modules/phoenix/BgServerPhoenix'
import BgServerGoogleplay from '~/modules/googleplay/BgServerGoogleplay'
import BgServerMailru from '~/modules/mailru/BgServerMailru'
import BgServerStarz from "~/modules/starz/BgServerStarz";


const FACEBOOK_INSTANT_GAME = process.env.FACEBOOK_INSTANT_GAME=="1"
const YANDEX_GAME = process.env.YANDEX_GAME=="1"
const PHOENIX_GAME = process.env.PHOENIX_GAME=="1"
const STARZ_GAME = process.env.STARZ_GAME=="1"
const YANDEX_DEV = process.env.YANDEX_DEV=="1"
const GOOGLEPLAY_GAME = process.env.GOOGLEPLAY_GAME == "1"
const MAILRU_GAME = process.env.MAILRU_GAME == '1'
//
// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)


export let server :BgServer

const socketPlugin: Plugin = async (context) => {
    try {
        console.log( 'Activating socket plugin.' )
        if(FACEBOOK_INSTANT_GAME) {
            server = new BgServerFb(context.$axios, context);
        }
        else if ( GOOGLEPLAY_GAME ) {
            server = new BgServerGoogleplay( context.$axios, context )
        }
        else if ( MAILRU_GAME || process.env.MAILRU_DEBUG ) {
            server = new BgServerMailru( context.$axios, context )
        }
        else if(YANDEX_GAME || YANDEX_DEV) {
            server = new BgServerYandex(context.$axios, context);
        }
        else if(PHOENIX_GAME) {
            server = new BgServerPhoenix(context.$axios, context);
        }
        else if(STARZ_GAME) {
            server = new BgServerStarz(context.$axios, context);
        }
        else {
            server = new BgServer(context.$axios);
        }
    }
    catch(e) {
        console.error( 'Failed to instantiate server:', e )
    }

    console.log( 'Initializing server ...' )
    await server.init();     //in no fb enviropmnent it hungs!
    await context.store.dispatch("init", server);
}

export default socketPlugin
